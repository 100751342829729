import React from "react";
import "./index.css";
import Board from "./Board";
import MakePage from "./MakePage";
import ErrorPage from "./Error";
import ErrorBoundary from "./ErrorBoundary";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AnalyticsTracker } from "./googleAnalytics";

function MainRouter() {
  const router = createBrowserRouter([
    {
      path: "/x",
      element: (
        <ErrorBoundary fallback={<p>Something went wrong</p>}>
          <Board width={10} height={10} size={60} />
        </ErrorBoundary>
      ),
    },
    {
      path: "/",
      element: <MakePage />,
    },
    {
      path: "/error",
      element: <ErrorPage />,
    },
    {
      path: "/*",
      element: <div>not found</div>,
    },
  ]);
  return (
    <>
      <RouterProvider router={router}>
        <AnalyticsTracker />
      </RouterProvider>
    </>
  );
}
export default MainRouter;

import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import ShareButton from "./ShareButton";
import { useReactToPrint } from "react-to-print";
import {
  base64ToBytes,
  bytesToBase64,
  emojiToCodePoint,
  codePointToEmoji,
} from "./utils";

import JSConfetti from "js-confetti";
import WindowBox from "./WindowBox";
import { Point, PointSet } from "./puzzleGeometry";
import { useTimer, useWindowDimensions } from "./hooks";

const colorPallette = {
  0: "transparent",
  1: "mint",
  2: "pink",
};

function Board(props) {
  const [searchParams] = useSearchParams();
  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);
  const seconds = useTimer(started, finished);
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { isCentered, windowWidth } = useWindowDimensions(started);
  let width;
  let height;
  let texts;
  let title;
  let answers_get;
  let dontshow;
  let selectedEmoji;
  const navigate = useNavigate();

  const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   documentTitle: "cross word puzzle",
  //   // onAfterPrint: () => alert("파일 다운로드 후 알림창 생성 가능"),
  // });
  width = base64ToBytes(decodeURIComponent(searchParams.get("size")));
  height = base64ToBytes(decodeURIComponent(searchParams.get("size")));
  texts = base64ToBytes(decodeURIComponent(searchParams.get("texts")));
  title = base64ToBytes(decodeURIComponent(searchParams.get("title")));
  colorPallette[1] = base64ToBytes(
    decodeURIComponent(searchParams.get("color1"))
  );
  colorPallette[2] = base64ToBytes(
    decodeURIComponent(searchParams.get("color2"))
  );
  answers_get = base64ToBytes(decodeURIComponent(searchParams.get("ans")));
  dontshow =
    base64ToBytes(decodeURIComponent(searchParams.get("dontshow"))) === "false"
      ? false
      : true;
  selectedEmoji = searchParams.get("emj");

  document.body.style.backgroundColor = colorPallette[1];

  const confettiRef = useRef(null);

  const handleCelebrate = () => {
    confettiRef.current.addConfetti({
      emojis: [
        codePointToEmoji(selectedEmoji) != ""
          ? codePointToEmoji(selectedEmoji)
          : "😘",
      ],
    });
  };

  useEffect(() => {
    confettiRef.current = new JSConfetti();
    if (
      ![
        width,
        height,
        texts,
        title,
        colorPallette[1],
        colorPallette[2],
        answers_get,
      ].every((variable) => variable !== undefined)
    ) {
      navigate("/error");
    }
  }, []); // 빈 배열은 컴포넌트가 마운트될 때 useEffect 내부의 함수가 한 번만 실행되게 합니다.

  const pixelSize =
    (windowWidth / width) * 0.94 < 32 ? (windowWidth / width) * 0.94 : 32;
  const fontSize =
    ((windowWidth / width) * 0.94) / 2.5 < 16
      ? ((windowWidth / width) * 0.94) / 2.5
      : 16;
  const titleSize =
    (((windowWidth / width) * 0.94) / 2.5) * 2 < 36
      ? (((windowWidth / width) * 0.94) / 2.5) * 2
      : 36;

  // useEffect(() => {
  //   const handleResize = () => setWindowWidth(window.innerWidth);

  //   window.addEventListener("resize", handleResize);

  //   // 컴포넌트 언마운트 시 리스너 제거
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  let txt_lst;
  let answers_lst;
  try {
    txt_lst = answers_get.split("|");
    answers_lst = [];
    for (let i = 0; i < txt_lst.length; i++) {
      let tmp = txt_lst[i].split(",");
      answers_lst.push({
        name: tmp[0],
        edge_coord: new PointSet(
          new Point(tmp[1], tmp[2]),
          new Point(tmp[3], tmp[4])
        ),
        found: false,
      });
    }
  } catch (e) {
    console.error("Caught:", e.message);
    navigate("/error");
  }

  const [isMouseDown, setIsMouseDown] = React.useState(false);
  const [selectedColor, setSelectedColor] = React.useState(1);
  const [startPoint, setStartPoint] = React.useState({ row: -1, col: -1 });
  const [endPoint, setEndPoint] = React.useState({ row: -1, col: -1 });
  const [currentBoard, setCurrentBoard] = useState(
    Array.from({ length: height }, () => new Array(Number(width)).fill(0))
  );
  const [answerBoard, setAnswerBoard] = useState(
    Array.from({ length: height }, () => new Array(Number(width)).fill(0))
  );
  const [roundRateBoard, setRoundRateBoard] = useState(
    Array.from({ length: height }, () =>
      Array.from({ length: width }, () => [50, 50, 50, 50])
    )
  );

  useEffect(() => {
    setCurrentBoard(answerBoard.map((row) => [...row]));
    showTmpLine();
    checkCorrect();
    // setEndPoint({ row: props.x, col: props.y });
    // setStartPoint({ row: props.x, col: props.y });
  }, [answerBoard]);

  const [answers, setAnswers] = useState(answers_lst);
  // answers data sample
  // const [answers, setAnswers] = useState([
  //   {
  //     name: "hello",
  //     edge_coord: new PointSet(new Point(0, 0), new Point(4, 4)),
  //     found: false,
  //   },
  //   {
  //     name: "world",
  //     edge_coord: new PointSet(new Point(0, 0), new Point(0, 5)),
  //     found: false,
  //   },
  // ]);
  useEffect(() => {
    let allFound = answers.every((item) => item.found === true);
    if (allFound) {
      // setStarted(false);
      setFinished(true);

      handleCelebrate();
    }
  }, [answers]);

  function showTmpLine() {
    if (
      startPoint.row != -1 &&
      startPoint.col != -1 &&
      endPoint.row != -1 &&
      endPoint.col != -1
    ) {
      //시작점 표시
      currentBoardChanger(startPoint.row, startPoint.col, 2);
      //끝점 표시
      currentBoardChanger(endPoint.row, endPoint.col, 2);
      //직선일 경우 사이 표시
      if (startPoint.row == endPoint.row) {
        for (
          let i = Math.min(startPoint.col, endPoint.col);
          i <= Math.max(startPoint.col, endPoint.col);
          i++
        ) {
          currentBoardChanger(endPoint.row, i, 2);
        }
      }
      if (startPoint.col == endPoint.col) {
        for (
          let i = Math.min(startPoint.row, endPoint.row);
          i <= Math.max(startPoint.row, endPoint.row);
          i++
        ) {
          currentBoardChanger(i, endPoint.col, 2);
        }
      }

      //대각선(기울기1 -1일 경우)
      if (
        (startPoint.row - endPoint.row) / (startPoint.col - endPoint.col) ==
        1
      ) {
        if (startPoint.row < endPoint.row) {
          for (let i = 0; i <= endPoint.row - startPoint.row; i++) {
            currentBoardChanger(startPoint.row + i, startPoint.col + i, 2);
          }
        } else {
          for (let i = 0; i <= startPoint.row - endPoint.row; i++) {
            currentBoardChanger(endPoint.row + i, endPoint.col + i, 2);
          }
        }
      }
      if (
        (startPoint.row - endPoint.row) / (startPoint.col - endPoint.col) ==
        -1
      ) {
        if (startPoint.row < endPoint.row) {
          for (let i = 0; i <= endPoint.row - startPoint.row; i++) {
            currentBoardChanger(startPoint.row + i, startPoint.col - i, 2);
          }
        } else {
          for (let i = 0; i <= startPoint.row - endPoint.row; i++) {
            currentBoardChanger(endPoint.row + i, endPoint.col - i, 2);
          }
        }
      }
    }
  }

  function drawAnswerLineStraight(
    point1_row,
    point1_col,
    point2_row,
    point2_col
  ) {
    let axis = 0;
    let start = 0;
    let end = 0;

    if (point1_row == point2_row) {
      //번쩨 row라서 col이 축임
      axis = point1_row;
      start = Math.min(point1_col, point2_col);
      end = Math.max(point1_col, point2_col);

      for (let i = start; i <= end; i++) {
        if ((i - start) % 2 == 1) {
          roundRateBoardChanger(axis, i, [0, 50, 0, 50]);
        } else {
          roundRateBoardChanger(axis, i, [50, 0, 50, 0]);
        }
        answerBoardChanger(axis, i, 1);
        // roundRateBoardChanger(endPoint.row, i, [50, 50, 50, 0]);
      }
      roundRateBoardChanger(axis, start, [50, 50, 50, 0]);
      if ((end - start) % 2 == 0) {
        roundRateBoardChanger(axis, end, [50, 0, 50, 50]);
      } else {
        roundRateBoardChanger(axis, end, [0, 50, 50, 50]);
      }
    } else {
      //row가 축
      axis = point1_col;
      start = Math.min(point1_row, point2_row);
      end = Math.max(point1_row, point2_row);

      for (let i = start; i <= end; i++) {
        if (i % 2 == 1) {
          roundRateBoardChanger(i, axis, [0, 50, 0, 50]);
        } else {
          roundRateBoardChanger(i, axis, [50, 0, 50, 0]);
        }
        answerBoardChanger(i, axis, 1);
        // roundRateBoardChanger(endPoint.row, i, [50, 50, 50, 0]);
      }
      roundRateBoardChanger(start, axis, [50, 0, 50, 50]);
      console.log(start, end);
      if ((start - end + 1) % 2 == 0) {
        roundRateBoardChanger(end, axis, [0, 50, 50, 50]);
      } else {
        roundRateBoardChanger(end, axis, [50, 50, 50, 0]);
      }
    }
  }

  function showTmpLineAnswer() {
    if (
      startPoint.row != -1 &&
      startPoint.col != -1 &&
      endPoint.row != -1 &&
      endPoint.col != -1
    ) {
      //시작점 표시
      answerBoardChanger(startPoint.row, startPoint.col, 1);
      //끝점 표시
      answerBoardChanger(endPoint.row, endPoint.col, 1);

      //직선일 경우 사이 표시

      if (startPoint.row == endPoint.row || startPoint.col == endPoint.col) {
        drawAnswerLineStraight(
          startPoint.row,
          startPoint.col,
          endPoint.row,
          endPoint.col
        );
      }

      //대각선(기울기1 -1일 경우)
      if (
        // 대각선 -1 기울기 \
        (startPoint.row - endPoint.row) / (startPoint.col - endPoint.col) ==
        1
      ) {
        if (startPoint.row < endPoint.row) {
          //시작점 차이때문에 분기 나눔. 어느 방향에서 시작했나 차이임
          for (let i = 0; i <= endPoint.row - startPoint.row; i++) {
            roundRateBoardChanger(
              startPoint.row + i,
              startPoint.col + i,
              [0, 50, 0, 50]
            );
            answerBoardChanger(startPoint.row + i, startPoint.col + i, 1);
            //시작점 표시
            roundRateBoardChanger(
              startPoint.row,
              startPoint.col,
              [50, 50, 0, 50]
            );
            //끝점 표시
            roundRateBoardChanger(endPoint.row, endPoint.col, [0, 50, 50, 50]);
          }
        } else {
          for (let i = 0; i <= startPoint.row - endPoint.row; i++) {
            roundRateBoardChanger(
              endPoint.row + i,
              endPoint.col + i,
              [0, 50, 0, 50]
            );
            answerBoardChanger(endPoint.row + i, endPoint.col + i, 1);

            //시작점 표시

            roundRateBoardChanger(
              startPoint.row,
              startPoint.col,
              [0, 50, 50, 50]
            );
            //끝점 표시
            roundRateBoardChanger(endPoint.row, endPoint.col, [50, 50, 0, 50]);
          }
        }
      }
      if (
        // 대각선 1 기울기 / 이거 그냥 좌표가 아니라서 (3,1) (2,2) 생각해보면됨.
        (startPoint.row - endPoint.row) / (startPoint.col - endPoint.col) ==
        -1
      ) {
        if (startPoint.row < endPoint.row) {
          for (let i = 0; i <= endPoint.row - startPoint.row; i++) {
            roundRateBoardChanger(
              startPoint.row + i,
              startPoint.col + i,
              [50, 0, 50, 0]
            );
            answerBoardChanger(startPoint.row + i, startPoint.col - i, 1);
          }
        } else {
          for (let i = 0; i <= startPoint.row - endPoint.row; i++) {
            roundRateBoardChanger(
              endPoint.row + i,
              endPoint.col + i,
              [50, 0, 50, 0]
            );
            answerBoardChanger(endPoint.row + i, endPoint.col - i, 1);
          }
        }
        if (startPoint.row > endPoint.row) {
          //시작점 표시
          roundRateBoardChanger(
            startPoint.row,
            startPoint.col,
            [50, 50, 50, 0]
          );
          //끝점 표시
          roundRateBoardChanger(endPoint.row, endPoint.col, [50, 0, 50, 50]);
        } else {
          //시작점 표시
          roundRateBoardChanger(
            startPoint.row,
            startPoint.col,
            [50, 0, 50, 50]
          );
          //끝점 표시
          roundRateBoardChanger(endPoint.row, endPoint.col, [50, 50, 50, 0]);
        }
      }
      // setCurrentBoard(answerBoard.map((row) => [...row]));
    }

    setCurrentBoard(answerBoard.map((row) => [...row]));
  }

  function checkCorrect() {
    if (
      startPoint.row != -1 &&
      startPoint.col != -1 &&
      endPoint.row != -1 &&
      endPoint.col != -1
    ) {
      //시작점 끝점을 세트로 만들어서 리스트 순차적으로 비교함

      for (let i = 0; i < answers.length; i++) {
        if (
          answers[i].found === false &&
          answers[i].edge_coord.equals(
            new PointSet(
              new Point(startPoint.row, startPoint.col),
              new Point(endPoint.row, endPoint.col)
            )
          )
        ) {
          showTmpLineAnswer();
          setAnswers(
            answers.map((answer) =>
              answer.name === answers[i].name
                ? { ...answer, found: true }
                : answer
            )
          );
        }
      }
    }
  }

  const currentBoardChanger = (x, y, color) => {
    setCurrentBoard((currentData) => {
      return currentData.map((row, rowIndex) => {
        if (y === rowIndex) {
          return row.map((cell, cellIndex) => {
            if (x === cellIndex) {
              return color;
            }
            return cell;
          });
        }
        return row;
      });
    });
  };

  const answerBoardChanger = (x, y, color) => {
    setAnswerBoard((currentData) => {
      return currentData.map((row, rowIndex) => {
        if (y === rowIndex) {
          return row.map((cell, cellIndex) => {
            if (x === cellIndex) {
              return color;
            }
            return cell;
          });
        }
        return row;
      });
    });
  };

  const roundRateBoardChanger = (x, y, roundsLst) => {
    setRoundRateBoard((currentData) => {
      return currentData.map((row, rowIndex) => {
        if (y === rowIndex) {
          return row.map((cell, cellIndex) => {
            if (x === cellIndex) {
              return roundsLst;
            }
            return cell;
          });
        }
        return row;
      });
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",

        justifyContent: isCentered ? "center" : "start",
        height: isCentered ? "90vh" : "fit-content",
        touchAction: "none",
        userSelect: "none",
      }}
      className="no-drag"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "80%",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            {/* <button
              onClick={handlePrint}
              style={{
                backgroundColor: colorPallette[2], //"transparent",
                border: "solid 2px black",
                borderRadius: "20%",
              }}
            >
              <PrintIcon fontSize="small" />
            </button> */}
          </div>
          <div>
            <div
              style={{
                fontSize: titleSize,

                fontWeight: "bolder",
                marginRight: "auto",
                marginLeft: "auto",
              }}
            >
              {title}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {" "}
              {finished ? (
                <div onPointerUp={handleCelebrate}>🎉</div>
              ) : (
                <div>⏱️</div>
              )}
              <div
                style={{ color: "rgba(0,0,0,0.6)", fontSize: fontSize * 0.8 }}
              >
                {seconds} sec
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <WindowBox decoColor={colorPallette[2]}>
          {started ? (
            <div
              ref={componentRef}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  padding: "10px",
                  backgroundColor: "white",
                }}
              >
                {answers.map((i) =>
                  i.found == false ? (
                    dontshow ? (
                      <div
                        style={{
                          padding: "10px",
                          fontSize: fontSize,
                        }}
                      >
                        {"O".repeat(i.name.length)}
                      </div>
                    ) : (
                      <div
                        style={{
                          padding: "10px",
                          fontSize: fontSize,
                        }}
                      >
                        {i.name}
                      </div>
                    )
                  ) : (
                    <div
                      style={{
                        padding: "10px",
                        fontSize: fontSize,
                        textDecoration: "line-through",
                      }}
                    >
                      {i.name}
                    </div>
                  )
                )}
              </div>
              <div style={{ padding: 20 }}>
                {currentBoard.map((i, index_i) => (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {i.map((j, index_j) => (
                      <Pixel
                        selectedColor={selectedColor}
                        key={index_i + "_" + index_j}
                        isMouseDown={isMouseDown}
                        size={pixelSize}
                        currentColor={currentBoard[index_i][index_j]}
                        currentBorder={roundRateBoard[index_i][index_j]}
                        x={index_j}
                        y={index_i}
                        arrDataChanger={currentBoardChanger}
                        setIsMouseDown={setIsMouseDown}
                        setStartPoint={setStartPoint}
                        setEndPoint={setEndPoint}
                        showTmpLine={showTmpLine}
                        answerBoard={answerBoard}
                        setCurrentBoard={setCurrentBoard}
                        letter={texts[index_i * width + index_j]}
                        checkCorrect={checkCorrect}
                        fontSize={fontSize}
                        startPoint={startPoint}
                      ></Pixel>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <button
              style={{
                width: "100%",
                height: fontSize * 3,
                fontSize: fontSize,
                backgroundColor: colorPallette[0],
                border: "1px solid white",
              }}
              onClick={() => setStarted(true)}
            >
              press to start
            </button>
          )}
        </WindowBox>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            color: "rgba(0,0,0,0.6)",
            fontSize: fontSize * 0.8,
          }}
        >
          You can share this puzzle by clicking
          <ShareButton color={colorPallette[2]} fontSize={fontSize} />
          or simply copy and share this page's link.
        </div>
      </div>{" "}
    </div>
  );
}

function Pixel(props) {
  const handleMouseOver = () => {
    if (props.startPoint.row !== -1 && props.startPoint.col !== -1) {
      props.setCurrentBoard(props.answerBoard.map((row) => [...row]));
      props.setEndPoint({ row: props.x, col: props.y });
      props.showTmpLine();
    }
  };

  const handleMouseDown = (e) => {
    e.target.releasePointerCapture(e.pointerId);
    props.setStartPoint({ row: props.x, col: props.y });
    props.setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    props.checkCorrect();
    props.setCurrentBoard(props.answerBoard.map((row) => [...row]));
    props.setIsMouseDown(false);
    props.setStartPoint({ row: -1, col: -1 });
    props.setEndPoint({ row: -1, col: -1 });
  };

  return (
    <div
      style={{
        width: props.size,
        height: props.size,
        touchAction: "none",
        userSelect: "none",
      }}
      onPointerEnter={handleMouseOver}
      onPointerDown={handleMouseDown}
      onPointerUp={handleMouseUp}
    >
      <div
        style={{
          width: props.size,
          height: props.size,
          borderRadius:
            props.currentBorder[0] +
            "% " +
            props.currentBorder[1] +
            "% " +
            props.currentBorder[2] +
            "% " +
            props.currentBorder[3] +
            "%",
          backgroundColor: colorPallette[props.currentColor],
          borderBottom: props.currentColor == 1 ? "solid 2px black" : "none",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          userSelect: "none",
          fontSize: props.fontSize,
          transition: "border-radius 1s ease-in-out",
        }}
      >
        {props.letter}
      </div>
    </div>
  );
}

export default Board;

import React from "react";
import ShareIcon from "@mui/icons-material/Share";

function ShareButton(props) {
  const shareInfo = {
    title: "puzzle link",
    text: "try this puzzle!",
    url: window.location.href,
  };

  const handleShare = () => {
    if (navigator.share) {
      // 웹 공유 API 지원 여부 확인
      navigator
        .share(shareInfo)
        .then(() => console.log("공유 성공!"))
        .catch((error) => console.log("공유 실패:", error));
    } else {
      // Fallback: 복사, 링크 공유 등의 대체 방법 사용
      console.log("웹 공유 API를 지원하지 않는 브라우저입니다.");
      console.log(shareInfo);
      // alert(shareInfo);
      // 예: 클립보드에 URL 복사
      navigator.clipboard
        .writeText(shareInfo.url)
        .then(() => console.log("URL이 클립보드에 복사되었습니다."))
        .catch((error) => console.log("클립보드 복사 실패:", error));
      alert("Link is copied!");
    }
  };

  return (
    <button
      onClick={handleShare}
      style={{
        backgroundColor: props.color,
        border: "solid 2px black",
        // borderRadius: "20%",
        width: "100%",
        height: props.fontSize * 2.5,
        fontSize: props.fontSize,
        padding: "10px",
        border: "1px solid black",
      }}
    >
      here
      {/* <ShareIcon fontSize={props.fontSize} /> */}
    </button>
  );
}

export default ShareButton;

// analytics.js
import ReactGA from "react-ga";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TRACKING_ID = process.env.GA_TRACKING_ID;

export function initializeAnalytics() {
  ReactGA.initialize(TRACKING_ID);
}

export function trackPageview(path) {
  ReactGA.pageview(path);
}

export function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return null; // 시각적 요소는 없으며, 추적만을 목적으로 함
}

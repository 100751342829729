function WindowBox(props) {
  const { decoColor, children } = props;

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        border: "2px solid black",
        boxShadow: `5px 5px 0 0 ${decoColor}`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        // touchAction: "none",
        transition: "all 1s ease-in-out",
      }}
    >
      <div
        style={{
          height: 30,
          backgroundColor: decoColor,
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <div
          style={{
            marginRight: "auto",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              border: "1px solid black",
              width: 15,
              height: 15,
              margin: "5px 0px 5px 5px",
              borderRadius: "50%",
            }}
          />
          <div
            style={{
              border: "1px solid black",
              width: 15,
              height: 15,
              margin: "5px 0px 5px 5px",
              borderRadius: "50%",
            }}
          />
          <div
            style={{
              border: "1px solid black",
              width: 15,
              height: 15,
              margin: "5px 0px 5px 5px",
              borderRadius: "50%",
            }}
          />
        </div>
      </div>
      {children}
    </div>
  );
}

export default WindowBox;

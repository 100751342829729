import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ErrorPage() {
  const navigate = useNavigate();
  return (
    <div>
      <div>the url you got is not valid</div>
      <div>instead you can make a new puzzle</div>
      <button onClick={() => navigate("/make")}>make a new one!</button>
    </div>
  );
}

export default ErrorPage;

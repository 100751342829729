export function base64ToBytes(str) {
  try {
    const binString = atob(str);
    return new TextDecoder().decode(
      Uint8Array.from(binString, (m) => m.codePointAt(0))
    );
  } catch (e) {
    return undefined;
  }
}

export function bytesToBase64(str) {
  const binString = Array.from(new TextEncoder().encode(str), (x) =>
    String.fromCodePoint(x)
  ).join("");
  return btoa(binString);
}

export function emojiToCodePoint(emoji) {
  return emoji.codePointAt(0).toString(16);
}

export function codePointToEmoji(codePoint) {
  try {
    return String.fromCodePoint(parseInt(codePoint, 16));
  } catch (error) {
    return "";
  }
  // return String.fromCodePoint(parseInt(codePoint, 16));
}

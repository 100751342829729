import React, { useEffect, useRef, useState } from "react";
import { EmojiButton } from "@joeattardi/emoji-button";

function EmojiSelector(props) {
  const [selectedEmoji, setSelectedEmoji] = useState(props.selectedEmoji);
  const picker = useRef(null);
  const buttonRef = useRef(null);
  const togglePicker = () => {
    if (picker.current && buttonRef.current) {
      picker.current.togglePicker(buttonRef.current);
    }
  };

  useEffect(() => {
    // Emoji Button 인스턴스 생성
    picker.current = new EmojiButton();

    // 이모지 선택 이벤트 리스너 설정
    picker.current.on("emoji", (selection) => {
      setSelectedEmoji(selection.emoji);
      props.setSelectedEmoji(selection.emoji);
    });

    // 버튼에 클릭 이벤트 리스너를 연결하여 이모지 피커를 토글
    const button = buttonRef.current;
    button.addEventListener("pointerup", togglePicker);

    return () => {
      // 컴포넌트 언마운트 시 이벤트 리스너 해제
      if (button) {
        button.removeEventListener("pointerup", togglePicker);
      }
    };
  }, []);

  return (
    <div>
      <button ref={buttonRef} type="button">
        {selectedEmoji}
      </button>
      {/* <p>Selected Emoji: {selectedEmoji}</p> */}
    </div>
  );
}

export default EmojiSelector;

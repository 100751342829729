import React, { useState, useEffect, useRef } from "react";

export function useTimer(started, finished) {
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    // 1초마다 seconds를 업데이트하는 interval 설정
    let interval = null; // interval을 null로 초기화

    if (started && !finished) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    } else if (finished) {
      clearInterval(interval);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [started, finished]);
  return seconds;
}

export function useWindowDimensions(started = null) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isCentered, setIsCentered] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      checkCentering();
    };

    const checkCentering = () => {
      const screenHeight = window.innerHeight;
      const contentHeight = document.body.offsetHeight;
      setIsCentered(contentHeight < screenHeight);
    };

    // 최초 실행 시 확인
    checkCentering();

    window.addEventListener("resize", handleResize);

    // 컴포넌트 언마운트 시 리스너 제거
    return () => window.removeEventListener("resize", handleResize);
  }, [started]);

  return { windowWidth, isCentered };
}

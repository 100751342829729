import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer.js";
import "emoji-picker-element";
import EmojiSelector from "./EmojiSelector.js";
import { useWindowDimensions } from "./hooks";
import WindowBox from "./WindowBox.js";

function emojiToCodePoint(emoji) {
  return emoji.codePointAt(0).toString(16);
}

function MakePage() {
  const [title, setTitle] = useState("");

  const [puzzleSize, setPuzzleSize] = useState(5);
  const [color1, setColor1] = useState("#ABDEE6");
  const [color2, setColor2] = useState("#FFFFB5");
  const [wordlst, setwordLst] = useState(["", "", "", "", ""]);
  const [dontShow, setdontshow] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState("🥳");

  let words = [];

  let points = [];

  let word_map = [];
  let nameNpoints = new Map();
  let navigate = useNavigate();
  useEffect(() => {
    document.body.style.backgroundColor = color1;
    // 컴포넌트가 언마운트되거나, backgroundColor가 변경되기 전에 배경색을 원래대로 되돌림
    return () => {
      document.body.style.backgroundColor = ""; // 기본 배경색으로 리셋
    };
  }, [color1]);
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { isCentered, windowWidth } = useWindowDimensions(wordlst);

  const mainWidth = windowWidth * 0.8 < 800 ? windowWidth * 0.8 : 800;
  const fontSize = mainWidth * 0.06 < 16 ? mainWidth * 0.06 : 16;
  const titleSize = mainWidth * 0.12 < 36 ? mainWidth * 0.12 : 36;

  function makeCrossWord(e) {
    e.preventDefault(); // 폼 제출 시 페이지 리로드 방지

    word_map = Array.from({ length: puzzleSize }, function () {
      // console.log(puzzleSize);
      // console.log(
      //   typeof puzzleSize,
      //   puzzleSize,
      //   new Array(Number(puzzleSize)).fill(" ")
      // );

      // console.log(puzzleSize, new Array(puzzleSize).fill(" "));
      return new Array(Number(puzzleSize)).fill(" ");
    });

    console.log("word_map", JSON.parse(JSON.stringify(word_map)));

    words = wordlst.slice();
    words.sort((a, b) => b.length - a.length);

    var letters = new Set([]);
    for (let i = 0; i < words.length; i++) {
      let tmp = [];

      for (let j = 0; j < words[i].length; j++) {
        tmp.push(words[i][j]);
      }
      letters = new Set([...letters, ...tmp]);
    }
    points = [];
    for (let i = 0; i < puzzleSize; i++) {
      for (let j = 0; j < puzzleSize; j++) {
        points.push([i, j]);
      }
    }
    shuffleArray(points);

    if (!dfs(0)) {
      alert(
        "Failed to create the puzzle with the required length. Please increase the length."
      );
      // console.log("fail");
      // console.log("word_map", JSON.parse(JSON.stringify(word_map)));
    } else {
      // console.log("word_map", JSON.parse(JSON.stringify(word_map)));

      const lettersArray = Array.from(letters);
      for (let i = 0; i < puzzleSize; i++) {
        for (let j = 0; j < puzzleSize; j++) {
          if (word_map[i][j] === " ") {
            word_map[i][j] =
              lettersArray[Math.floor(Math.random() * lettersArray.length)];
          }
        }
      }
      // console.log("word_map", JSON.parse(JSON.stringify(word_map)));
      // console.log(nameNpoints);

      let ans = "";
      for (let i = 0; i < wordlst.length; i++) {
        ans = ans + wordlst[i] + "," + nameNpoints.get(wordlst[i]);
        if (i < wordlst.length - 1) {
          ans = ans + "|";
        }
      }
      console.log(ans);
      let texts = "";
      for (let i = 0; i < puzzleSize; i++) {
        for (let j = 0; j < puzzleSize; j++) {
          texts = texts + word_map[i][j];
        }
      }
      console.log("texts", texts);

      navigate(
        "/x/?title=" +
          encodeURIComponent(bytesToBase64(String(title))) +
          "&texts=" +
          encodeURIComponent(bytesToBase64(String(texts))) +
          "&ans=" +
          encodeURIComponent(bytesToBase64(String(ans))) +
          "&size=" +
          encodeURIComponent(bytesToBase64(String(Number(puzzleSize)))) +
          "&color1=" +
          encodeURIComponent(bytesToBase64(String(color1))) +
          "&color2=" +
          encodeURIComponent(bytesToBase64(String(color2))) +
          "&dontshow=" +
          encodeURIComponent(bytesToBase64(String(dontShow))) +
          "&emj=" +
          String(emojiToCodePoint(selectedEmoji))
      );
    }
  }

  function bytesToBase64(str) {
    const binString = Array.from(new TextEncoder().encode(str), (x) =>
      String.fromCodePoint(x)
    ).join("");
    return btoa(binString);
  }

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      // 0에서 i 사이의 임의의 인덱스를 선택
      const j = Math.floor(Math.random() * (i + 1));
      // array[i]와 array[j]를 교환
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  function can_place(word, point, direction) {
    // console.log("can_place: ", word, point, direction);
    for (let i = 0; i < word.length; i++) {
      if (
        0 <= point[0] + direction[0] * i &&
        point[0] + direction[0] * i < puzzleSize &&
        0 <= point[1] + direction[1] * i &&
        point[1] + direction[1] * i < puzzleSize
      ) {
        if (
          !(
            word_map[point[0] + direction[0] * i][
              point[1] + direction[1] * i
            ] === " "
          ) ||
          word_map[point[0] + direction[0] * i][point[1] + direction[1] * i] ===
            word[i]
        ) {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  }

  function place_word(word, point, direction) {
    console.log("placing");
    for (let i = 0; i < word.length; i++) {
      word_map[point[0] + direction[0] * i][point[1] + direction[1] * i] =
        word[i];
    }
  }

  function dfs(idx) {
    console.log("idx: ", idx);
    if (idx == words.length) {
      return true;
    }

    let directions = [
      [0, 1],
      [1, 1],
      [1, 0],
      [1, -1],
      [-1, 1],
      [-1, 0],
      [0, -1],
      [-1, -1],
    ];

    shuffleArray(directions);

    for (let pointIdx = 0; pointIdx < points.length; pointIdx++) {
      for (let dirIdx = 0; dirIdx < directions.length; dirIdx++) {
        console.log(
          "trying placing in ",
          points[pointIdx],
          words[idx],
          directions[dirIdx]
        );
        if (can_place(words[idx], points[pointIdx], directions[dirIdx])) {
          place_word(words[idx], points[pointIdx], directions[dirIdx]);
          nameNpoints.set(
            words[idx],
            String(points[pointIdx][1]) +
              String(",") +
              String(points[pointIdx][0]) +
              String(",") +
              String(
                points[pointIdx][1] +
                  directions[dirIdx][1] * (words[idx].length - 1)
              ) +
              String(",") +
              String(
                points[pointIdx][0] +
                  directions[dirIdx][0] * (words[idx].length - 1)
              )
          );
          if (dfs(idx + 1)) {
            return true;
          }
          console.log("enter");
          place_word(
            " " * words[idx].length,
            points[pointIdx],
            directions[dirIdx]
          );
        }
      }
    }
    return false;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: isCentered ? "center" : "start",
        height: isCentered ? "90vh" : "fit-content",
      }}
      className="no-drag"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: fontSize,
        }}
      >
        <div
          style={{
            fontSize: fontSize * 1.5,
            fontWeight: "bolder",
          }}
        >
          MAKE CROSSWORD PUZZLE
        </div>

        <br />
        <WindowBox decoColor={color2}>
          <form
            onSubmit={makeCrossWord}
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: fontSize,
              backgroundColor: "white",
              borderRadius: 20,
              // border: "2px solid black",
              boxShadow: "5px 5px 0 0 " + color2,
              overflow: "hidden",
            }}
          >
            <div
              style={{
                padding: "30px 60px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div>
                <div style={{}}>title</div>
                <input
                  type="text"
                  value={title}
                  required
                  pattern="^[^\s]{2,10}$"
                  onInvalid={(event) => {
                    event.target.setCustomValidity(
                      "The word must be longer than 2 characters, shorter than 10, and must not contain any spaces."
                    );
                  }}
                  onChange={(e) => {
                    e.target.setCustomValidity("");
                    return setTitle(e.target.value);
                  }}
                  style={{
                    width: "100%",
                    fontSize: fontSize,
                    border: "1px solid black",
                  }}
                />
              </div>
              <div>
                <input
                  type="checkbox"
                  value={dontShow}
                  onChange={(e) => setdontshow(e.target.checked)}
                  style={{
                    width: fontSize,
                    margin: 0,
                    border: "1px solid black",
                  }}
                />
                don't show words
              </div>
              <div style={{}}>width</div>
              <div>
                <input
                  type="number"
                  min={5}
                  max={16}
                  value={puzzleSize}
                  onChange={function (e) {
                    word_map = Array.from({ length: puzzleSize }, () =>
                      new Array(puzzleSize).fill(" ")
                    );
                    return setPuzzleSize(e.target.value);
                  }}
                  style={{ fontSize: fontSize, border: "1px solid black" }}
                />
              </div>
              <div style={{}}>emoji theme</div>

              <EmojiSelector
                selectedEmoji={selectedEmoji}
                setSelectedEmoji={setSelectedEmoji}
              />
              <div>
                <div style={{}}>color theme</div>
                <input
                  type="color"
                  value={color1}
                  onChange={(e) => setColor1(e.target.value)}
                  style={{
                    width: "50%",
                    height: fontSize * 2,
                    border: "1px solid black",
                  }}
                />
                <input
                  type="color"
                  value={color2}
                  onChange={(e) => setColor2(e.target.value)}
                  style={{
                    width: "50%",
                    height: fontSize * 2,
                    border: "1px solid black",
                  }}
                />
              </div>
              <div style={{}}>
                type words!
                {wordlst.map((content, idx) => (
                  <WordBlank
                    key={idx}
                    num={idx}
                    word={content}
                    setwordLst={setwordLst}
                    puzzleSize={puzzleSize}
                  />
                ))}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    setwordLst([...wordlst, ""]);
                  }}
                  style={{
                    borderRadius: "50%",
                    width: fontSize * 2,
                    height: fontSize * 2,
                    backgroundColor: color1,
                    border: "1px solid black",
                  }}
                >
                  +
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <button
                  type="submit"
                  style={{
                    width: "100%",
                    height: fontSize * 3,
                    fontSize: fontSize,
                    padding: "10px",
                    backgroundColor: color1,
                    border: "1px solid black",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </WindowBox>
      </div>
      <Footer></Footer>
    </div>
  );
}

function WordBlank(props) {
  return (
    <div>
      {String(props.num + 1).padStart(2, "0") + ": "}
      <input
        type="text"
        value={props.word}
        required
        pattern={"^[^\\s]{2," + String(props.puzzleSize) + "}$"}
        onInvalid={(event) => {
          event.target.setCustomValidity(
            "The word must be longer than 2 characters, shorter than the maximum length, and must not contain any spaces."
          );
        }}
        onChange={(e) => {
          e.target.setCustomValidity("");
          return props.setwordLst((prevArray) => {
            const newArray = [...prevArray];
            newArray[props.num] = e.target.value;
            return newArray;
          });
        }}
        style={{
          width: props.fontSize,
          height: props.fontSize,
          fontSize: props.fontSize,
          border: "1px solid black",
        }}
      />
    </div>
  );
}

export default MakePage;

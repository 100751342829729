import React from "react";

function Footer() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "rgba(0, 0, 0, 0.35)",
      }}
    >
      <br></br>
      <div>© 2024 gongboo. All rights reserved.</div>
      <div>email: gongboolearn@gmail.com</div>
      <div>gongboo.github.io</div>
    </div>
  );
}

export default Footer;

export class Point {
  constructor(row, col) {
    this.row = row;
    this.col = col;
  }

  print() {
    console.log(`(${this.row}, ${this.col})`);
  }

  equals(point) {
    if (point.row == this.row && point.col == this.col) {
      return true;
    }
    return false;
  }
}

export class PointSet {
  constructor(pointA, pointB) {
    this.pointA = pointA;
    this.pointB = pointB;
  }

  equals(pointSet) {
    if (
      (pointSet.pointA.equals(this.pointA) &&
        pointSet.pointB.equals(this.pointB)) ||
      (pointSet.pointA.equals(this.pointB) &&
        pointSet.pointB.equals(this.pointA))
    ) {
      return true;
    }
    return false;
  }
}
